<template>
  <b-card :title="`${$t('General.Update')} ${$t('Restriction')}`">
    <validation-observer ref="simpleRules">
      <b-overlay
        :show="showOverLay"
        rounded="sm"
        :opacity="0.5"
      >
        <b-form ref="formName">
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('AccountingInfo.Restriction No')"
                label-for="restrictionNo"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('AccountingInfo.Restriction No')"
                  rules="required"
                >
                  <b-form-input
                    v-model="restriction_no"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('AccountingInfo.Restriction No')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('General.Date')"
                label-for="date"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('General.Date')"
                  rules="required"
                >
                  <flat-pickr
                    v-model="date"
                    class="form-control"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('General.Date')"
                    local="en"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                :name="$t('Financial Year')"
                rules="required"
              >
                <b-form-group
                  :label="$t('Financial Year')"
                  label-for="financialYear"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    v-model="financial_year"
                    :state="financial_year === null ? false : true"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="financial_year_option"
                    :placeholder="$t('Financial Year')"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false:null"
                  >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                :name="$t('Cost Center')"
                rules="required"
              >
                <b-form-group
                  :label="$t('Cost Center')"
                  label-for="costCenter"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    v-model="cost_center"
                    :state="cost_center === null ? false : true"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                    :options="cost_center_option"
                    :placeholder="$t('Cost Center')"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false:null"
                  >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="10">
              <b-form-group
                :label="$t('AccountingInfo.Statement')"
                label-for="statement"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('AccountingInfo.Statement')"
                  rules="required"
                >
                  <b-form-input
                    v-model="statement"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('AccountingInfo.Statement')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                :label="$t('General.Active')"
                label-for="active"
              >
                <b-form-checkbox
                  v-model="active"
                  class="custom-control-primary"
                >
                  {{ $t('General.Active') }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                :label="$t('General.Notes')"
                label-for="notes"
              >
                <b-form-textarea
                  v-model="notes"
                  :placeholder="$t('General.Notes')"
                  rows="3"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <!-- alert -->
              <b-alert
                v-height-fade.appear
                variant="danger"
                :show="showError"
                class="text-left p-1"
              >
                <ul class="list-style-icons mb-0">
                  <li><feather-icon icon="ArrowRightIcon" />{{ apiErrors }}</li>
                </ul>
              </b-alert>
            </b-col>
            <b-col cols="6" />
            <b-col cols="12">
              <h4 class="p-1 text-left">
                {{ `${$t('General.Add')} ${$t('AccountingInfo.Restriction Details')}` }}
              </h4>
            </b-col>
            <b-col cols="12">
              <b-form ref="formGrid">
                <b-row class="p-1">
                  <b-col md="6">
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('Account Tree')"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('Account Tree')"
                        label-for="accountCredit"
                        :state="errors.length > 0 ? false:null"
                      >
                        <v-select
                          v-model="account_tree"
                          :state="account_tree === null ? false : true"
                          :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                          :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                          :options="account_tree_option"
                          :placeholder="$t('Account Tree')"
                        />
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false:null"
                        >
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      :label="`${$t('AccountingInfo.Statement')} ${$t('Detail')}`"
                      label-for="statementDetail"
                    >
                      <b-form-input
                        v-model="statement_detail"
                        :placeholder="`${$t('AccountingInfo.Statement')} ${$t('Detail')}`"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      :label="$t('General.Credit')"
                      label-for="credit"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('General.Credit')"
                        rules="required|regex:^([0-9]+)$"
                      >
                        <b-form-input
                          v-model="credit"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="$t('General.Credit')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      :label="$t('General.Debit')"
                      label-for="debit"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('General.Debit')"
                        rules="required|regex:^([0-9]+)$"
                      >
                        <b-form-input
                          v-model="debit"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="$t('General.Debit')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="5" />
                  <b-col cols="2 text-center p-1 mt-1">
                    <b-button
                      variant="info"
                      type="button"
                      block
                      @click.prevent="addGrid"
                    >
                      {{ $t('General.Add') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-col>
            <b-col cols="5" />
            <b-col cols="12">
              <vue-good-table
                :columns="columns"
                :rows="rows"
                :rtl="direction"
                :search-options="{
                  enabled: true,
                  externalQuery: searchTerm }"
                :pagination-options="{
                  enabled: true,
                  perPage:pageLength
                }"
                responsive
              >
                <template
                  slot="table-row"
                  slot-scope="props"
                >

                  <!-- Column: Action -->
                  <span v-if="props.column.field === 'action'">
                    <span>
                      <b-dropdown
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                      >
                        <template v-slot:button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="text-body align-middle mr-25"
                          />
                        </template>
                        <b-dropdown-item
                          @click="updateGrid(props.row.id)"
                        >
                          <feather-icon
                            icon="Edit2Icon"
                            class="mr-50"
                          />
                          <span>{{ $t('General.Edit') }}</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="removeGrid(props.row.id)">
                          <feather-icon
                            icon="TrashIcon"
                            class="mr-50"
                          />
                          <span>{{ $t('General.Delete') }}</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </span>
                  </span>

                </template>

                <!-- pagination -->
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap">
                        {{ $t('Showing 1 to') }}
                      </span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['3','5','10','50','100']"
                        class="mx-1"
                        @input="(value)=>props.perPageChanged({currentPerPage:value})"
                      />
                      <span class="text-nowrap "> {{ $t('of') }} {{ props.total }} {{ $t('General.entries') }} </span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :per-page="pageLength"
                        first-number
                        last-number
                        align="right"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @input="(value)=>props.pageChanged({currentPage:value})"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
            <b-col cols="3" />
            <b-col cols="6 text-center p-1">
              <b-button
                variant="primary"
                type="button"
                block
                @click="validationForm"
              >
                {{ $t('General.Update') }}
              </b-button>
            </b-col>
            <b-col cols="3" />
          </b-row>
        </b-form>
      </b-overlay>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BOverlay, BAlert, BFormTextarea, BFormCheckbox,
  BPagination, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightFade } from '@core/directives/animations'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import costCenter from '@/service/accounting/cost-center'
import accountTree from '@/service/accounting/account-tree'
import restriction from '@/service/accounting/restriction'
import financialYear from '@/service/accounting/financial-year'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BOverlay,
    BAlert,
    BFormTextarea,
    vSelect,
    flatPickr,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
  },
  directives: {
    'height-fade': heightFade,
  },
  data() {
    return {
      id: router.currentRoute.params.id,
      date: '',
      amount: 0,
      restriction_no: '',
      required,
      statement: '',
      notes: '',
      cost_center: '',
      cost_center_option: [],
      account_tree: '',
      account_tree_option: [],
      financial_year: '',
      financial_year_option: [],
      credit: '',
      debit: '',
      active: true,
      statement_detail: '',
      showError: false,
      showOverLay: false,
      apiErrors: [],
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: this.$t('Account Tree'),
          field: store.state.appConfig.layout.isRTL ? 'account_tree.name_ar' : 'account_tree.name_en',
          filterOptions: {
            enabled: true,
            placeholder: `${this.$t('General.Search')} ${this.$t('Account Tree')}`,
          },
        },
        {
          label: this.$t('AccountingInfo.Statement'),
          field: 'statement',
          filterOptions: {
            enabled: true,
            placeholder: `${this.$t('General.Search')} ${this.$t('AccountingInfo.Statement')}`,
          },
        },
        {
          label: this.$t('General.Credit'),
          field: 'credit',
          filterOptions: {
            enabled: true,
            placeholder: `${this.$t('General.Search')} ${this.$t('General.Credit')}`,
          },
        },
        {
          label: this.$t('General.Debit'),
          field: 'debit',
          filterOptions: {
            enabled: true,
            placeholder: `${this.$t('General.Search')} ${this.$t('General.Debit')}`,
          },
        },
        {
          label: this.$t('General.Action'),
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      count: 0,
      isUpdate: false,
      vauleUpdate: '',
      locale: this.$i18n.locale,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.layout.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    credit: {
      immediate: true,
      handler() {
        this.debit = 0
      },
    },
    debit: {
      immediate: true,
      handler() {
        this.credit = 0
      },
    },
  },
  async created() {
    await this.getApiSelect()
    this.showOverLay = true
    await restriction.oneAxios(this.id).then(response => {
      this.date = response.data.date
      this.amount = response.data.amount
      this.restriction_no = response.data.restriction_no
      this.statement = response.data.statement
      this.notes = response.data.notes
      this.active = response.data.active === 1
      this.cost_center = this.cost_center_option.find(x => x.id === response.data.cost_center_id)
      this.financial_year = this.financial_year_option.find(x => x.id === response.data.financial_year_id)
      response.data.restriction_details.forEach(detail => {
        // eslint-disable-next-line no-plusplus
        this.count++
        const addData = {
          id: this.count,
          account_tree: this.account_tree_option.find(x => x.id === detail.account_tree_id),
          account_tree_id: detail.account_tree_id,
          statement: detail.statement,
          credit: detail.credit,
          debit: detail.debit,
        }
        this.rows.push(addData)
      })
      this.showError = false
    }).catch(error => {
      this.apiErrors = error
      this.showError = true
    }).finally(() => { this.showOverLay = false })
  },
  methods: {
    addGrid() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (this.isUpdate) {
            const addData = {
              id: this.vauleUpdate,
              account_tree: this.account_tree,
              account_tree_id: this.account_tree.id,
              statement: this.statement_detail,
              credit: this.credit,
              debit: this.debit,
            }
            const foundIndex = this.rows.findIndex(x => x.id === this.vauleUpdate)
            this.rows[foundIndex] = addData
            this.rows.push({})
            this.rows.pop()
            this.vauleUpdate = ''
            this.isUpdate = false
          } else {
            // eslint-disable-next-line no-plusplus
            this.count++
            const addData = {
              id: this.count,
              account_tree: this.account_tree,
              account_tree_id: this.account_tree.id,
              statement: this.statement_detail,
              credit: this.credit,
              debit: this.debit,
            }
            this.rows.push(addData)
          }
          // this.total_price = this.rows.reduce((a, b) => +a + +(b.quantity * b.price), 0)
          this.clearGrid()
          this.$refs.formGrid.reset()
          // this.postApiProduct()
        }
      })
    },
    async validationForm() {
      await this.fullGrid()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.putApi()
        }
        this.switchLoc()
      })
    },
    async putApi() {
      this.showOverLay = true
      const user = JSON.parse(localStorage.getItem('userData'))
      await restriction.putAxios(this.id, {
        date: this.date,
        amount: this.amount,
        cost_center_id: this.cost_center.id,
        financial_year_id: this.financial_year.id,
        restriction_no: this.restriction_no,
        active: this.active,
        branch_id: user.employee.branch_id,
        statement: this.statement,
        notes: this.notes,
        user_id: user.id,
        restrictionDetails: this.rows,
      }).then(() => {
        this.$refs.formName.reset()
        this.showError = false
        this.showToast(this.$t('General.Successfully'), 'CheckCircleIcon', this.$t('General.SavedSuccessfully'), 'success')
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
        this.showToast(this.$t('General.Error'), 'XCircleIcon', this.$t('General.SavedError'), 'danger')
      }).finally(() => {
        this.showOverLay = false
      })
    },
    clearForm() {
      this.date = ''
      this.name_en = ''
      this.active = true
      this.cost_center = ''
      this.restriction_no = ''
      this.financial_year = ''
      this.notes = ''
      this.amount = 0
      this.count = 0
      this.rows = []
      this.clearGrid()
    },
    clearGrid() {
      this.account_tree = ''
      this.statement_detail = ''
      this.credit = ''
      this.debit = ''
      this.$refs.simpleRules.reset()
    },
    fullGrid() {
      this.account_tree = '.'
      this.statement_detail = '.'
      this.credit = 0
      this.debit = 0
      this.$refs.simpleRules.reset()
    },
    showToast(title, icon, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    },
    switchLoc() {
      this.locale = this.$i18n.locale
      localize(this.locale)
    },
    async getApiSelect() {
      this.showOverLay = true
      await costCenter.getAxios().then(response => {
        this.cost_center_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await financialYear.getAxios().then(response => {
        this.financial_year_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await accountTree.getAxios().then(response => {
        this.account_tree_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      }).finally(() => { this.showOverLay = false })
    },
    updateGrid(id) {
      const updateValue = this.rows.find(x => x.id === id)
      this.clearGrid()
      if (updateValue) {
        this.vauleUpdate = id
        this.account_tree = updateValue.account_tree
        this.account_tree_id = updateValue.account_tree.id
        this.statement_detail = updateValue.statement
        this.credit = updateValue.credit
        this.debit = updateValue.debit
        this.isUpdate = true
      }
    },
    removeGrid(id) {
      this.rows.splice(this.rows.findIndex(a => a.id === id), 1)
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
